<template>
  <b-modal
    v-bind="$attrs"
    v-model="innerValue"
    modal-class="sfmodal"
    header-close-variant="black modal-header-button"
  >
    <template #modal-header="{ close }">
      <slot name="modal-header">
        <h5
          id="__BVID__45___BV_modal_title_"
          class="modal-title pt-1"
        >
          {{ title }}
        </h5>
      </slot>

      <button
        type="button"
        :aria-label="trans('general.close')"
        class="close text-black modal-header-button"
        @click="close"
      >
        ×
      </button>
    </template>

    <template #default>
      <div
        v-if="subtitle"
        class="text-muted fs-1"
      >
        {{ subtitle }}
      </div>
      <slot name="default"></slot>
    </template>
    <template #modal-title>
      <slot name="modal-title"></slot>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'SfModal',
  props: {
    titleClass: { type: String, required: false, default: null },
    title: { type: String, required: false, default: null },
    subtitle: { type: String, required: false, default: null },
    value: { type: Boolean, required: false, default: null },
  },
  emits: ['input'],
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
